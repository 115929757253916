export const updateLabel = {
    terms_and_conditions: 'Terms and Conditions',
    checkout_slider_img: 'Checkout slider image',
    hero_banner: ' Hero Banner - (Home page big image)',
    listing_slider_img: 'listing slider image',
    privacy_policy: 'Privacy policy',
};

export const ROLES = {
    ADMIN: 'admin',
    PROPERTY_ADMIN: 'property-admin',
    OUTLET_ADMIN: 'outlet-admin',
};
