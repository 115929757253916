import { Route, Routes } from 'react-router-dom';
import RequireAuth from './auth/RequireAuth';
import ConfigureKiosk from './pages/ConfigureKiosk';
import Layout from './pages/Layout';
import Login from './pages/Login';
import MenuEdit from './pages/MenuEdit';
import AnalyticsDashboard from './pages/Analytics';

function App() {
    const NotFound = () => <p>Page not found</p>;
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <RequireAuth>
                        <Layout />
                    </RequireAuth>
                }
            >
                <Route index element={<MenuEdit />} />
                <Route exact path='/menu' element={<MenuEdit />} />
                <Route exact path='/analytics' element={<AnalyticsDashboard />} />
                <Route exact path='/configure' element={<ConfigureKiosk />} />
            </Route>
            <Route exact path='/login' element={<Login />} />
            <Route path='*' exact={true} element={<NotFound />} />
        </Routes>
    );
}

export default App;
