import React from 'react';
import { Card, Table, Select } from 'antd';
import { ROLES } from '../constants/typecode';
import { formatNumber } from '../helpers/commonHelper';

const { Option } = Select;

const RevenueComparison = ({
    analyticsData,
    revenueCompare,
    onComparisonChange,
    selectedKiosks = [],
    selectedProperties = [],
    selectedOutlets = [],
}) => {
    // Early return if the necessary analytics data is missing
    if (!analyticsData?.revenueComparision || !analyticsData?.overview) {
        return null;
    }

    // Destructure overview data
    const { total_revenue, average_order_value, total_orders } =
        analyticsData.overview;

    // Get the current role from localStorage
    const role = localStorage.getItem('role');

    // Define columns for the table
    const columns = [
        {
            title: 'Name',
            dataIndex: 'group_name',
            key: 'group_name',
            align: 'center',
            width: '25%',
        },
        {
            title: 'Orders',
            dataIndex: 'total_orders',
            key: 'total_orders',
            align: 'center',
            width: '25%',
            sorter: (a, b) => a.total_orders - b.total_orders,
        },
        {
            title: 'Revenue (with taxes)',
            dataIndex: 'total_revenue',
            key: 'total_revenue',
            render: (value) => `₹${formatNumber(value)}`,
            align: 'center',
            width: '25%',
            sorter: (a, b) => a.total_revenue - b.total_revenue,
        },
        {
            title: 'Average Order Value (with taxes)',
            dataIndex: 'average_order_value',
            key: 'average_order_value',
            render: (value) => `₹${formatNumber(value)}`,
            align: 'center',
            width: '25%',
            sorter: (a, b) => a.total_orders - b.total_orders,
        },
    ];

    // Check which comparisons should be displayed
    const showTable = () => {
        if (
            revenueCompare === 'property' &&
            (selectedProperties.includes('ALL') ||
                selectedProperties.length > 1)
        ) {
            return true;
        }
        if (
            revenueCompare === 'outlet' &&
            (selectedOutlets.includes('ALL') || selectedOutlets.length > 1)
        ) {
            return true;
        }
        if (
            revenueCompare === 'kiosk' &&
            (selectedKiosks.includes('ALL') || selectedKiosks.length > 1)
        ) {
            return true;
        }
        return false;
    };

    return (
        <>
            <div className='order-details-container'>
                <Card className='metric-card'>
                    <div className='metric-card-value'>{total_orders}</div>
                    <div className='metric-card-key'>Total Orders</div>
                </Card>
                <Card className='metric-card'>
                    <div className='metric-card-value'>
                        ₹{formatNumber(total_revenue)}
                    </div>
                    <div className='metric-card-key'>
                        Total Revenue (with taxes)
                    </div>
                </Card>
                <Card className='metric-card'>
                    <div className='metric-card-value'>
                        ₹{formatNumber(average_order_value)}
                    </div>
                    <div className='metric-card-key'>
                        Average Order Value (with taxes)
                    </div>
                </Card>
            </div>

            <Card
                title='Revenue Comparison'
                className='revenue-overview-card'
                extra={
                    (revenueCompare !== 'kiosk' || role!==ROLES.OUTLET_ADMIN) && (
                        <Select
                            style={{ width: 200 }}
                            placeholder='Revenue Comparison'
                            onChange={onComparisonChange}
                            value={revenueCompare}
                        >
                            {role === ROLES.ADMIN && (
                                <Option value='property'>Cinema wise</Option>
                            )}
                            {(role === ROLES.ADMIN ||
                                role === ROLES.PROPERTY_ADMIN) && (
                                <Option value='outlet'>Outlet wise</Option>
                            )}
                            <Option value='kiosk'>Kiosk wise</Option>
                        </Select>
                    )
                }
            >
                {showTable() && (
                    <Table
                        dataSource={analyticsData.revenueComparision}
                        columns={columns}
                        rowKey='group_name' // Ensure each row has a unique key
                        pagination={{
                            pageSize: 10, // Set the maximum number of rows per page
                        }}
                        className='revenue-table'
                    />
                )}
            </Card>
        </>
    );
};

export default RevenueComparison;
