import { Input, useToast } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../assets/vserveCinemaLogo.png';
import { debounce } from '../helpers/commonHelper';
import { deleteCookie } from '../helpers/cookieHelper';
import useClickOutside from '../hooks/useClickOutside';
import { globalSearch, logout } from '../services/apiService';
import { ROLES } from '../constants/typecode';

const Header = () => {
    const [result, setResult] = useState([]);
    const [isSearchListVisible, setIsSearchListVisible] = useState(false);
    const navigate = useNavigate();
    const searchRef = useRef(null);
    const searchInputRef = useRef(null);
    const toast = useToast();
    useClickOutside(searchInputRef, () => setIsSearchListVisible(false));
    const role = localStorage.getItem('role');
    const userHasAccess = [
        ROLES.ADMIN,
        ROLES.PROPERTY_ADMIN,
        ROLES.OUTLET_ADMIN,
    ].includes(role);

    const logoutClickHandler = async (e) => {
        e.preventDefault();
        try {
            const response = await logout();
            if (response.status === 200) {
                deleteCookie('token');
                localStorage.removeItem('role');
                navigate('/menu', { replace: true });
            }
        } catch (error) {}
    };
    const handleSearchChange = (value) => {
        if (searchRef && searchRef.current) searchRef.current.value = value;
        if (value && value.length >= 3) {
            expensiveFn(value);
        }
    };
    let expensiveFn = async (str) => {
        try {
            const response = await globalSearch(str);
            if (response.status === 200) {
                const data = response.data?.data || [];
                setIsSearchListVisible(true);
                setResult(data.menus || []);
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        } catch (error) {
            const { response } = error;
            if (response.status === 422 || response.status === 503) {
                const message =
                    response.data.message ||
                    'Something went Wrong!! Please try again.';
                toast({
                    title: message,
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
            if (response.status === 403 || response.status === 401) {
                toast({
                    title: "You don't have permission for this action.",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        }
    };
    const handleLinkClick = (e, url) => {
        e.stopPropagation();
        setIsSearchListVisible(false);
        navigate(url, { replace: true });
    };
    const betterFn = debounce(handleSearchChange, 500);
    return (
        <div className='header'>
            <div
                className='logo'
                style={{ backgroundImage: `url(${Logo})` }}
            ></div>
            <ul className='nav-link'>
                <li className='search-input' ref={searchInputRef}>
                    <Input
                        placeholder='Search By Name'
                        value={searchRef.value}
                        type='search'
                        onChange={(e) => betterFn(e.target.value)}
                    />
                    {isSearchListVisible && result.length > 0 && (
                        <ul className='dropdown'>
                            {result.map((menu) => (
                                <li
                                    key={menu.id}
                                    onClick={(e) =>
                                        handleLinkClick(
                                            e,
                                            `/menu?mid=${menu.id}`
                                        )
                                    }
                                >
                                    {menu.name}
                                    <ul>
                                        {menu.categories.map((cat) => (
                                            <li
                                                key={cat.id}
                                                onClick={(e) =>
                                                    handleLinkClick(
                                                        e,
                                                        `/menu?mid=${menu.id}&cid=${cat.id}`
                                                    )
                                                }
                                            >
                                                {cat.name}
                                                <ul>
                                                    {cat.items.map((item) => (
                                                        <li
                                                            key={item.id}
                                                            onClick={(e) =>
                                                                handleLinkClick(
                                                                    e,
                                                                    `/menu?mid=${menu.id}&cid=${cat.id}&iid=${item.id}`
                                                                )
                                                            }
                                                        >
                                                            {item.name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
                {/* <li>
                    <NavLink to={'/configure'}>Configure Kiosk</NavLink>
                </li> */}
                <li className="active">
                    <NavLink to={'/menu'}>Menu Management</NavLink>
                </li>
                {userHasAccess && (
                    <li className="active">
                        <NavLink to={'/analytics'}>Analytics</NavLink>
                    </li>
                )}
                <li>
                    <span onClick={(e) => logoutClickHandler(e)}>Logout</span>
                </li>
            </ul>
        </div>
    );
};

export default Header;
